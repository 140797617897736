<template>
  <a-layout class="rays-tuner-simplfiy-home">
    <a-layout-header class="header">
      <div class="back" @click="backClick" v-if="user.role_id == '1'">
        <svg-icon icon-class="chevron-left" class="top_icon back_icon" />
        <span class="back-con">返回</span>
      </div>
      <div class="top_title" v-if="user.role_id == '2'">RaysTuner</div>
      <a-dropdown v-if="user.role_id == '2'">
        <a class="top_icon_con" @click.prevent>
          <a-badge :count="0">
            <svg-icon icon-class="person" class="top_icon user" />
          </a-badge>
        </a>
        <template #overlay>
          <a-menu @click="accountClick">
            <a-menu-item key="1">
              <a href="javascript:;">退出登录</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </a-layout-header>
    <a-layout>
      <a-layout-sider class="left-con">
        <a-menu
          mode="inline"
          v-model:selectedKeys="accountHome.menuKey"
          @click="clickMenu"
          class="left-menu"
        >
          <a-menu-item key="1" v-if="user.role_id == '2'">
            <span>设备管理</span>
          </a-menu-item>
          <a-menu-item key="2" v-if="user.role_id == '2'">
            <span>成员管理</span>
          </a-menu-item>
          <a-menu-item key="3">
            <span>账户设置</span>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>

      <a-layout-content class="right-con">
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue';
import router from '@/routes';
import { loginOut, setEnterpriseHomeInfo } from '@/models/tunerStorage';
import store from '@/vuex/store';

export default defineComponent({
  name: 'AccountHome',
  components: {},
  setup() {
    const user = store.state.user.userinfo;
    const accountHome = computed(() => store.state.enterprise.home || {});

    const accountClick = ({ key }) => {
      // console.log('accountClick', key);
      switch (key) {
        case '1': //退出登录
          loginOut();
          break;
        default:
          break;
      }
    };
    const clickMenu = ({ key }) => {
      //   console.log('clickMenu', key);
      accountHome.value.menuKey = [key];
      setEnterpriseHomeInfo(accountHome.value);
      let path;
      switch (key) {
        case '1':
          path = '/accounthome/devicemanage';
          break;
        case '2':
          path = '/accounthome/devicemanage';
          break;
        case '3':
          path = '/accounthome/devicemanage';
          break;
        default:
          break;
      }
      router.push({
        path,
      });
    };
    const backClick = () => {
      router.push({
        path: '/mainhome/simplifyhome/pat',
      });
    };

    onMounted(() => {
      if (user.role_id == '1') {
        accountHome.value.menuKey = ['3'];
      } else if (user.role_id == '2') {
        accountHome.value.menuKey = ['1'];
      }
      setEnterpriseHomeInfo(accountHome.value);
    });

    return {
      user,
      accountHome,
      accountClick,
      clickMenu,
      backClick,
    };
  },
});
</script>

<style scoped>
.rays-tuner-simplfiy-home {
  width: 100%;
  height: 100%;
}
.header {
  position: relative;
  height: 48px;
  padding-top: 12px;
  padding-right: 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #d8d8d8;
}
.header .top_icon {
  width: 24px;
  height: 24px;
}
.header .back {
  position: absolute;
  top: 0;
  left: 24px;
  width: 156px;
  height: 48px;
  line-height: 48px;
  color: #aaaaaa;
}
.header .back:hover {
  color: #1890ff;
  cursor: pointer;
}
.header .back .back_icon {
  vertical-align: middle;
}
.header .back .back-con {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
}
.top_title {
  float: left;
  width: 97px;
  height: 24px;
  font-size: 20px;
  font-family: Segoe UI, Segoe UI-Bold Italic;
  font-weight: 700;
  font-style: italic;
  text-align: CENTER;
  color: #222;
  line-height: 24px;
}
.top_icon_con {
  width: 48px;
  height: 47px;
  padding-left: 12px;
  margin-top: -12px;
  float: right;
}
.top_icon_con:hover {
  background-color: #f0f0f0;
}
.top_icon_con .user {
  width: 24px;
  height: 24px;
  margin-top: -2px;
  margin-right: 5px;
  color: #aaa;
  font-size: 20px;
}
.top_icon_con:hover .user {
  color: #604bdc;
}

.left-con {
  width: 208px;
  border-right: 1px solid #d8d8d8;
  background-color: #f9f9f9;
}
.left-con .ant-menu {
  height: 100%;
  border-right: 0px;
  background-color: #f9f9f9;
}
.left-con .ant-menu-item {
  width: 192px;
  margin-left: 8px;
  padding-left: 8px !important;
  border-radius: 4px;
}
.left-con .ant-menu-item:active {
  background-color: #d8d8d8;
}
.left-con .ant-menu-item-selected {
  background-color: #d8d8d8;
}
.left-con .ant-menu-item-selected::after {
  opacity: 0;
}
.left-con .ant-menu-item > span {
  margin-left: 8px;
  font-size: 14px;
  color: #8c8c8c;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: LEFT;
}
</style>
